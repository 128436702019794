import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "comparison-with-reactlazy"
    }}><a parentName="h1" {...{
        "href": "#comparison-with-reactlazy",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Comparison with React.lazy`}</h1>
    <p>{`What are the differences between `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{` and `}<inlineCode parentName="p">{`@loadable/components`}</inlineCode>{`?`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/code-splitting.html#reactlazy"
      }}><inlineCode parentName="a">{`React.lazy`}</inlineCode></a>{` is the recommended solution for Code Splitting. It uses Suspense and it is maintained by React.`}</p>
    <p>{`If you are already using `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{` and if you are good with it, you don't need `}<inlineCode parentName="p">{`@loadable/component`}</inlineCode>{`.`}</p>
    <p>{`If you feel limited or if you need SSR, then `}<inlineCode parentName="p">{`@loadable/component`}</inlineCode>{` is the solution.`}</p>
    <h2 {...{
      "id": "comparison-table"
    }}><a parentName="h2" {...{
        "href": "#comparison-table",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Comparison table`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Library`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Suspense`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SSR`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Library splitting`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`import(\`./\${value}\`)`}</inlineCode></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.lazy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`❌`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`❌`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`❌`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`@loadable/component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "suspense"
    }}><a parentName="h2" {...{
        "href": "#suspense",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Suspense`}</h2>
    <p>{`Suspense is supported by `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{` and by `}<inlineCode parentName="p">{`@loadable/component`}</inlineCode>{`. `}<inlineCode parentName="p">{`@loadable/component`}</inlineCode>{` can also be used without Suspense.`}</p>
    <h2 {...{
      "id": "server-side-rendering"
    }}><a parentName="h2" {...{
        "href": "#server-side-rendering",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Server Side Rendering`}</h2>
    <p>{`Suspense is not available server-side and `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{` can only work with Suspense. That's why today, `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{` is not an option if you need Server Side Rendering.`}</p>
    <p><inlineCode parentName="p">{`@loadable/component`}</inlineCode>{` provides a complete solution to make `}<a parentName="p" {...{
        "href": "/docs/server-side-rendering/"
      }}>{`Server Side Rendering`}</a>{` possible.`}</p>
    <h2 {...{
      "id": "library-splitting"
    }}><a parentName="h2" {...{
        "href": "#library-splitting",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Library splitting`}</h2>
    <p><inlineCode parentName="p">{`@loadable/component`}</inlineCode>{` supports library splitting using render props. This is not possible with `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "full-dynamic-import"
    }}><a parentName="h2" {...{
        "href": "#full-dynamic-import",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Full dynamic import`}</h2>
    <p>{`Full dynamic import also called agressive code splitting is a feature supported by Webpack. It consists of passing a dynamic value to the dynamic `}<inlineCode parentName="p">{`import()`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// All files that could match this pattern will be automatically code splitted.
const loadFile = file => import(\`./\${file}\`)
`}</code></pre>
    <p>{`In React, it permits to create reusable components:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const AsyncPage = loadable(props => import(\`./\${props.page}\`))

function MyComponent() {
  return (
    <div>
      <AsyncPage page="Home" />
      <AsyncPage page="Contact" />
    </div>
  )
}
`}</code></pre>
    <p>{`This feature is not supported by `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "note-about-react-loadable"
    }}><a parentName="h2" {...{
        "href": "#note-about-react-loadable",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Note about `}<inlineCode parentName="h2">{`react-loadable`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/jamiebuilds/react-loadable"
      }}>{`react-loadable`}</a>{` was the recommended way for React code splitting for a long time. However, today it is not maintained any more and it is not compatible with Webpack v4+ and Babel v7+.`}</p>
    <p>{`If you use it, it is recommended to migrate to `}<inlineCode parentName="p">{`React.lazy`}</inlineCode>{` or `}<inlineCode parentName="p">{`@loadable/component`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      